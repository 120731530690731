// @ts-nocheck
import { useContext, useEffect, useRef, useState } from "react";
// lib
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { GetGameCategories } from "../src/api/gameManagement";
import { useLocation, useNavigate } from "react-router-dom";
// layouts
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
// routes
import MainRoute from "./routes/MainRoutes";
// css
import "./asset/css/home.css";
import "./asset/css/main.css";
// utils
import { toastError } from "./utils/toast";
// context
import { LoginContext } from "./store/context/LoginProvider";
// actionTypes
import {
  GAME_BAL_CHANGE,
  SET_USER_DATA,
  USER_LOGOUT,
} from "./store/actions/actionTypes";
// Api
import { getProfileApi } from "./api/profileApi";
import {
  getBannerApi,
  getHeaderTextApi,
  getHotProviers,
  getSocialLinkApi,
} from "./api/homeApi";
// Firebase
import {
  database,
  messaging,
  onMessage,
  onValue,
  ref,
} from "./firebase/myvip_firebase";
import Pusher from "pusher-js";
import Modals from "./components/modal";
import { prefixUrl } from "./constants/prefixRoutes";
import { ConfettieProvider } from "./hooks/Providers/Confettie";

function App() {
  const [text, setText] = useState(null);
  const [banner, setBanner] = useState([]);
  const [marquee, setMarquee] = useState("");
  const [loading, setLoading] = useState(false);
  const [socialLink, setSocialLink] = useState({
    facebook: "",
    viber: "",
    messenger: "",
    instagram: "",
    play_store: "",
    qr: "",
  });
  // context
  const [{ userData }, dispatch] = useContext(LoginContext);
  // react router dom
  let navigate = useNavigate();
  let location = useLocation();
  let path_name = location?.pathname;

  const slotRef = useRef(null);
  const fishRef = useRef(null);
  const casinoRef = useRef(null);
  const sportRef = useRef(null);
  const cardRef = useRef(null);

  const [provider, setProvider] = useState([]);
  const [hotLoading, setHotLoading] = useState(false);

  const HotProviders = async () => {
    setHotLoading(true);
    setProvider([
      //sport
      {
        title: "category.football",
        ref: sportRef,
        category: "sports",
        provider: await getHotProviers(10).then(
          (res) => res.success && res.data
        ),
      },
      //live casino
      {
        title: "category.liveCasino",
        ref: casinoRef,
        category: "LiveCasino",
        provider: await getHotProviers(6).then(
          (res) => res.success && res.data
        ),
      },
      //slot
      {
        title: "category.slotGame",
        ref: slotRef,
        category: "Slot",
        provider: await getHotProviers(2).then(
          (res) => res.success && res.data
        ),
      },
      //fishing
      {
        title: "category.fishingGame",
        ref: fishRef,
        category: "Fish",
        provider: await getHotProviers(3).then((res) => {
          return res.success && res.data;
        }),
      },
      //card
      {
        title: "category.arcade",
        ref: cardRef,
        category: "Arcade",
        provider: await getHotProviers(4).then((res) => {
          return res.success && res.data;
        }),
      },
      {
        title: "category.table",
        ref: cardRef,
        category: "Table",
        provider: await getHotProviers(5).then((res) => {
          return res.success && res.data;
        }),
      },
      {
        title: "category.crash",
        ref: cardRef,
        category: "Crash",
        provider: await getHotProviers(7).then((res) => {
          return res.success && res.data;
        }),
      },
      {
        title: "category.bingo",
        ref: cardRef,
        category: "Bingo",
        provider: await getHotProviers(9).then((res) => {
          return res.success && res.data;
        }),
      },
      {
        title: "category.Lottery",
        ref: cardRef,
        category: "Lottery",
        provider: await getHotProviers(8).then((res) => {
          setHotLoading(false);
          return res.success && res.data;
        }),
      },
    ]);
  };

  const pusher = new Pusher("6a15e5e2f69791f4128c", {
    cluster: "ap1",
    encrypted: true,
  });

  useEffect(() => {
    const channel = pusher.subscribe("chat");

    channel.bind("message", (data) => {
      setText(data.text);

      setTimeout(() => {
        setText(null);
      }, 20000);

      clearTimeout();
    });

    return () => {
      channel.unbind();
      pusher.unsubscribe("chat");
    };
  }, []);

  useEffect(() => {
    HotProviders();
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    GetGameCategories().then((res) => {
      res.success && dispatch({ type: "GAME_CATEGORY", payload: res.data });
      setLoading(false);
    });
    //Banner
    getBannerApi().then((res) => setBanner(res?.data || []));
    // Fetch Social Link
    getSocialLinkApi().then(setSocialLink);
    // Fetch Header Text
    getHeaderTextApi().then(setMarquee);
    // Firebase Noti: Foreground
    try {
      onMessage(messaging, (payload) => {
        const { title, body, icon } = payload.notification;
        const option = {
          body,
          icon,
        };
        new Notification(title, option);
      });
    } catch (error) {
      console.log("Firebase Noti Error:", error);
    }

    // Game Balance From Firebase
    try {
      if (userData?.id) {
        let refer = ref(database, `users/balances/${userData?.id}`);
        onValue(refer, (snapshot) => {
          let { last_balance } = snapshot.val();
          // Save or Update Game Balance
          dispatch({
            type: GAME_BAL_CHANGE,
            payload: {
              game_balance: last_balance || 0,
            },
          });
        });
      }
    } catch (error) {
      console.log("Firebase Game Balance:", error);
    }
    // Firebase Noti: Background
    try {
      //BroadcastChannel to recieve background messaging
      const channel = new BroadcastChannel("myvip_broadcast_channel");
      channel.onmessage = (event) => {
        let { title, body, icon } = event.data.key?.data;
        //notification body
        const options = {
          body,
          icon,
          vibrate: true, //vibrate will work only on the mobile device
        };
        new Notification(title, options);
      };
    } catch (error) {
      console.log("Broadcast Error:");
    }
  }, []);

  useEffect(() => {
    if (userData) {
      // Fetch Profile
      getProfileApi({ token: userData?.token })
        .then((res) => {
          let payload = {
            ...userData,
            lvl_2: res?.data?.lvl_2,
            balance: res?.data?.balance,
            user_code: res?.data?.user_code,
            refer_count: res?.data?.refer_count,
            game_refer_amt: res?.data?.game_refer_amt,
            turnover: res?.data?.turnover,
            point: res?.data?.point,
            p_balance: res?.data?.p_balance,
            name: res?.data?.name,
            image: res?.data?.image,
          };
          // Save or Update Profile Data
          dispatch({
            type: SET_USER_DATA,
            payload,
          });
        })
        .catch((err) => {
          if (err?.status === 401) {
            toastError("Session Expired, Please Login Again!");
            dispatch({
              type: USER_LOGOUT,
            });
            navigate("/login");
          }
        });
    }
  }, [path_name]);

  /**
   * An array route that are use for toggle navbar and footer ui.
   * @type {string[]}
   */

  const isPrefix = prefixUrl.includes(location.pathname);

  return (
    <>
      <Modals />
      <ToastContainer />
      <ConfettieProvider />
      <main id="BODY" className="position-relative">
        <div className="w-100 mx-auto main-route overflow-auto">
          <Navbar {...{ userData, isPrefix }} />
          <MainRoute
            banner={banner}
            provider={provider}
            hotLoading={hotLoading}
            marquee={text || marquee}
            socialLink={socialLink}
          />
        </div>

        {!isPrefix && <Footer />}
      </main>
    </>
  );
}

export default App;
